import CaseUnderConstruction from "../../../components/caseUnderConstruction/CaseUnderConstruction"


const PiacetContent = () => {
    return (
        <div className='case-content'>
            <CaseUnderConstruction />
        </div>
    )
}

export default PiacetContent